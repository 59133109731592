<template>
  <main class="main page-inside products-page categories-page">
    <div class="main-wrapper">
      <div class="section hero-section">
        <div class="title regular">
          <span>STORE</span>
          <router-link to="/cart" class="cart">
            <img
              v-if="$parent.cartContents.length"
              src="./../assets/cartActive.svg"
              class="img"
            />
            <img v-else src="./../assets/cart.svg" class="img" />
            <span class="desc">CART ({{ $parent.cartContents.length }})</span>
          </router-link>
        </div>
        <div class="wrapper">
          <transition name="fade">
            <div class="category-list" v-if="$parent.categories">
              <div
                class="category-list__item desc"
                v-for="(item, i) in $parent.categories"
                :key="i"
                @click="goToCategory(item)"
              >
                {{ item.title }}
              </div>
            </div>
          </transition>
          <transition name="fade">
            <div class="product-list-container" v-if="showPacks">
              <div class="active-category">
                <img src="../assets/heart.svg" class="img" />
                <div class="desc">Packs</div>
              </div>
              <div v-if="packsLoading" class="loading">Loading packs...</div>
              <div v-else-if="packsError" class="error">{{ packsError }}</div>
              <div
                v-else-if="packs && packs.length > 0"
                class="list products packs-list"
              >
                <div
                  class="item pack-item"
                  v-for="pack in packs"
                  :key="pack.id"
                >
                  <PackStoreItem
                    :pack="pack"
                    @addToCart="addPackToCart"
                    :currency="currency"
                  />
                </div>
              </div>
              <div v-else class="error">No packs available.</div>
            </div>
          </transition>
          <transition name="fade">
            <div class="product-list-container" v-if="showProducts">
              <div class="active-category">
                <img :src="imgDomain + activeCategory.image_url" class="img" />
                <div class="desc">{{ activeCategory.title }}</div>
              </div>
              <div class="list products" v-if="products">
                <div class="item" v-for="(item, i) in products" :key="i">
                  <ProductCard
                    :item="item"
                    :addToCartChosenItem="addToCartChosenItem"
                    @goToProduct="goToProduct"
                    @addToCart="addToCart"
                    :currency="currency"
                  />
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import ProductCard from "../components/ProductCard.vue";
import PackStoreItem from "@/components/pack/PackStoreItem.vue";
import usePacks from "../composables/usePacks";

export default {
  name: "Categories",
  props: ["addToCartChosenItem", "activeCategory", "currencyCode", "currency"],
  components: {
    ProductCard,
    PackStoreItem,
  },
  data() {
    return {
      imgDomain: "",
      products: [],
      packs: [],
      packsLoading: true,
      packsError: null,
      showPacks: false,
      showProducts: true,

      packsModule: usePacks(),
    };
  },
  watch: {
    activeCategory: function (newCategory) {
      if (newCategory) {
        if (newCategory.id === "packs") {
          this.showPacks = true;
          this.showProducts = false;
          this.fetchPacks();
        } else {
          this.showPacks = false;
          this.showProducts = true;
          this.getProducts();
        }
      }
    },
    $route: function (to) {
      if (to.path.includes("/store/packs")) {
        this.showPacks = true;
        this.showProducts = false;
        this.$emit("chooseCategoty", {
          id: "packs",
          title: "Packs",
          url: "/packs/",
        });

        this.fetchPacks();
      } else if (this.activeCategory && this.activeCategory.id !== "packs") {
        this.showPacks = false;
        this.showProducts = true;
        this.getProducts();
      }
    },
    currencyCode: function () {
      this.getProducts();

      this.fetchPacks();
    },
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;

    if (this.$route.path.includes("/store/packs")) {
      this.showPacks = true;
      this.showProducts = false;
      this.$emit("chooseCategoty", {
        id: "packs",
        title: "Packs",
        url: "/packs/",
      });
      this.fetchPacks();
    } else if (this.activeCategory) {
      this.showPacks = false;
      this.showProducts = true;
      this.getProducts();
    }
  },
  methods: {
    goToPacks() {
      this.showPacks = true;
      this.showProducts = false;
      this.$emit("chooseCategoty", {
        id: "packs",
        title: "Packs",
        url: "/packs/",
      });
      this.fetchPacks();
    },

    goToCategory(item) {
      this.showPacks = false;
      this.showProducts = true;
      this.$emit("chooseCategoty", item);
    },

    fetchPacks() {
      this.packsLoading = true;
      this.packsError = null;

      this.packsModule
        .fetchPacks()
        .then((data) => {
          if (data) {
            this.packs = data;
          } else {
            this.packs = this.packsModule.packs.value;
          }
          this.packsLoading = false;
        })
        .catch((error) => {
          console.error("Error fetching packs:", error);
          this.packsError = error.message || "Failed to load packs";
          this.packsLoading = false;
        });
    },

    getProducts() {
      if (
        !this.activeCategory ||
        !this.activeCategory.id ||
        this.activeCategory.id === "packs"
      )
        return;

      this.$http
        .get(
          `${process.env.VUE_APP_API}products/?category_id=${this.activeCategory.id}`
        )
        .then((res) => {
          if (res.data && res.data.payload) {
            this.products = res.data.payload;
          }
        })
        .catch((err) => {
          console.error("Error fetching products:", err);
        });
    },

    goToProduct(item) {
      this.$emit("goToProduct", item);
    },

    addToCart(item, id) {
      this.$emit("addToCart", item, id);
    },

    addPackToCart(pack, id, quantity) {
      this.$emit(
        "addToCart",
        {
          ...pack,
          quantity: quantity || 1,
        },
        id,
        quantity || 1,
        "pack"
      );
    },
  },
};
</script>

<style scoped>
.packs-list .pack-item {
  width: 100% !important;
  padding: 0 !important;
  background-color: transparent !important;
}

.loading,
.error {
  text-align: center;
  padding: 40px 0;
  font-size: 18px;
  font-family: "Nunito", sans-serif;
}

.error {
  color: #e61444;
}

@media (max-width: 767px) {
  .category-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .category-list__item {
    margin-bottom: 10px;
  }
}
</style>
