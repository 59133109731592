<template>
  <div class="pack-section" v-if="packs && packs.length > 0">
    <h2 class="title pack-section__title">CHOOSE PACK:</h2>
    <div v-if="loading" class="pack-section__loading">Loading packs...</div>
    <div v-else-if="error" class="pack-section__error">{{ error }}</div>
    <div v-else class="pack-section__grid">
      <div v-for="pack in packs" :key="pack.id" class="pack-section__item">
        <PackCard 
          :pack="pack" 
          @addToCart="addPackToCart" 
          :currency="currency"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PackCard from "./PackCard.vue";
import usePacks from "@/composables/usePacks";

export default {
  name: "PackSection",
  components: {
    PackCard,
  },
  props: {
    currency: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      packsModule: usePacks(),
      packs: [],
      loading: true,
      error: null
    };
  },
  watch: {
    currency: function() {
      this.fetchPacks();
    }
  },
  mounted() {
    this.fetchPacks();
  },
  methods: {
    fetchPacks() {
      this.loading = true;
      this.error = null;

      this.packsModule
        .fetchPacks(this.currency)
        .then((data) => {
          if (data) {
            this.packs = data;
          } else {
            this.packs = this.packsModule.packs.value;
          }
          this.loading = false;
        })
        .catch((error) => {
          console.error("Error fetching packs:", error);
          this.error = error.message || "Failed to load packs";
          this.loading = false;
        });
    },
    addPackToCart(pack, id, quantity = 1) {
      this.$emit("addToCart", {
        ...pack, 
        type: 'pack'
      }, id, quantity, 'pack');
    }
  }
};
</script>

<style scoped>
.pack-section {
  padding: 57px 16px 170px;
  background-color: #e0dfe6;
}

.pack-section__title {
  font-size: 44px;
  line-height: 52px;
  color: #0e0d13;
  text-align: center;
  margin-bottom: 136px;
}

.pack-section__loading,
.pack-section__error {
  text-align: center;
  font-size: 18px;
  padding: 40px 0;
}

.pack-section__error {
  color: #e61444;
}

.pack-section__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  max-width: 1440px;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .pack-section__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .pack-section {
    padding: 57px 16px 70px;
  }

  .pack-section__title {
    margin-bottom: 60px;
  }

  .pack-section__grid {
    grid-template-columns: 1fr;
  }
}
</style>
