<template>
  <div class="pack">
    <transition name="fade">
      <div class="item-added-overlay" v-if="addedItemId() == pack.id">
        <span class="added-text">Added</span>
      </div>
    </transition>
    <div class="pack-top">
      <div class="pack__icon">
        <img
          v-if="pack.image_url"
          :src="pack.image_url"
          alt="Pack icon"
          class="icon-img"
        />
        <div v-else class="icon-placeholder">{{ getPackInitial() }}</div>
      </div>
      <div class="pack__title">{{ pack.title }}</div>
      <div class="pack__description">{{ pack.description }}</div>
    </div>

    <div
      class="pack__contents"
      :class="{ open: isContentVisible }"
      @click="toggleContent"
    >
      <div class="contents-header">
        <span>Pack Contents</span>
        <svg
          width="10"
          height="6"
          viewBox="0 0 10 6"
          class="arrow"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 6V4H4V6H6ZM8 4V2H2V4H8ZM10 2V0H0V2H10Z"
            fill="currentColor"
          />
        </svg>
      </div>
      <div class="contents-items">
        <div class="item" v-for="item in pack.items" :key="item.id">
          {{ item.title }}
          <template v-if="item.quantity > 1"> ({{ item.quantity }}x) </template>
        </div>
      </div>
    </div>

    <div class="pack-bottom">
      <button class="buy-pack-btn" @click="addToCart">
        <span>BUY PACK</span>
        <span class="price">{{ formatPrice(pack.price) }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PackCard",
  props: {
    pack: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      default: ''
    }
  },
  inject: ['addedItemId', 'getCurrency'],
  data() {
    return {
      isContentVisible: false,
    };
  },
  methods: {
    toggleContent() {
      this.isContentVisible = !this.isContentVisible;
    },
    addToCart() {
      this.$emit('addToCart', this.pack, this.pack.id, 1, 'pack');
    },
    formatPrice(price) {
      // Use provided currency prop first, then fall back to inject
      const symbol = this.currency || this.getCurrency();

      if (price % 1 === 0) {
        return `${Number(price).toFixed(0)} ${symbol}`;
      } else {
        return `${Number(price).toFixed(2)} ${symbol}`;
      }
    },
    getPackInitial() {
      return this.pack.title ? this.pack.title.charAt(0).toUpperCase() : "P";
    },
  },
};
</script>

<style scoped>
.pack {
  background: transparent;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.pack-top {
  background: #ffffff;
  padding: 0px 28px 43px;
  position: relative;
}

.pack__icon {
  display: flex;
  justify-content: center;
  margin-top: -52px;
  margin-bottom: 24px;
  position: relative;
}

.icon-img {
  width: 94px;
  height: 94px;
  object-fit: contain;
}

.icon-placeholder {
  width: 94px;
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e0dfe6;
  color: #0e0d13;
  font-size: 36px;
  font-weight: 700;
  border-radius: 50%;
}

.pack__title {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #0e0d13;
  margin-bottom: 24px;
}

.pack__description {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #15141b;
  min-height: 76px;
  display: flex;
  justify-content: center;
}

.pack__contents {
  background: #eae8f0;
  cursor: pointer;
  width: 100%;
  text-transform: capitalize;
}

.contents-header {
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 16px 0 44px;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: #0e0d13;
  position: relative;
  padding-top: 13px;
  padding-bottom: 13px;
  text-align: center;
  transition: color 0.3s ease;
}

.contents-header span,
.contents-header svg {
  display: inline-block;
  vertical-align: middle;
}

.contents-header .arrow {
  margin-right: 10px;
  transition: transform 0.3s ease, color 0.3s ease;
  color: #0e0d13;
}

.pack__contents.open .contents-header {
  color: #e61444;
}

.pack__contents.open .contents-header .arrow {
  transform: rotate(180deg);
  color: #e61444;
}

.contents-items {
  overflow: hidden;
  max-height: 0;
  padding: 0 44px 0;
  transition: max-height 0.3s ease, padding 0.3s ease;
  text-align: center;
}

.pack__contents.open .contents-items {
  max-height: 500px;
  padding: 14px 44px 24px;
}

.contents-items .item {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #0e0d13;
  text-decoration: underline;
  text-align: center;
  transition: font-weight 0.2s ease-out;
}

.contents-items .item + .item {
  margin-top: 8px;
}

.pack-bottom {
  background: #ffffff;
  padding: 24px 53px;
}

.buy-pack-btn {
  max-width: 242px;
  margin: 0 auto;
  gap: 16px;
  height: 60px;
  background-image: url("../../assets/btn-bg-black.svg");
  background-color: transparent;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #0e0d13;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 28px 12px;
  cursor: pointer;
}

.buy-pack-btn .price {
  text-wrap: nowrap;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #5bc530;
}

@media (max-width: 767px) {
  .pack__icon {
    margin-top: -30px;
  }

  .icon-img,
  .icon-placeholder {
    width: 70px;
    height: 70px;
  }

  .icon-placeholder {
    font-size: 28px;
  }

  .pack-top {
    padding: 24px 16px 32px;
  }

  .pack__title {
    font-size: 20px;
    line-height: 28px;
    margin-top: 10px;
  }

  .pack-bottom {
    padding: 24px 16px;
  }
}
</style>
