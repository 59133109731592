<template>
  <main class="main cart-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section">
        <div class="wrapper">
          <div class="title regular">
            CART
            <router-link :to="'/store' + activeCategory.url" class="button">
              CONTINUE SHOPPING
            </router-link>
          </div>
          <div class="desc empty-desc" v-if="!hasItems">Cart is empty!</div>
          <div class="left" v-if="hasItems">
            <div class="title regular">Your basket</div>

            <div class="product-list">
              <template v-for="(item, i) in $parent.cartContents">
                <!-- Pack items -->
                <PackCartItem
                  v-if="item.type === 'pack'"
                  :key="`pack-${i}`"
                  class="pack-item"
                  :cartItem="item"
                  @increment="incrementPack"
                  @decrement="decrementPack"
                  @remove="removePack"
                />

                <!-- Regular items -->
                <div v-else class="item" :key="`product-${i}`">
                  <div class="delete" @click="removeItem(item, item.item_id)">
                    <img src="./../assets/bin.svg" class="img" />
                  </div>
                  <div class="preview">
                    <img :src="imgDomain + item.item.img_url" class="img" />
                  </div>
                  <div class="title name">{{ item.item.title }}</div>
                  <div class="count">
                    <div
                      class="count__item minus"
                      @click="decrementItem(item, item.item_id)"
                    ></div>
                    <input
                      type="number"
                      value="1"
                      v-model="item.count"
                      step="1"
                    />
                    <div
                      class="count__item plus"
                      @click="incrementItem(item.item, item.item_id)"
                    ></div>
                  </div>
                  <div class="price">
                    {{ item.item.price }}
                    <span class="currency">{{ $parent.currency }}</span>
                  </div>
                </div>
              </template>
            </div>
            <div class="row">
              <div class="desc">Total:</div>
              <div class="title">
                {{ $parent.totalSum }}
                <span class="currency">{{ $parent.currency }}</span>
              </div>
            </div>
          </div>
          <div class="right" v-if="hasItems">
            <div class="wrapper">
              <div class="title regular">Checkout</div>
              <button @click="toOrder" class="button red">
                <span>PROCEED TO CHECKOUT</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import PackCartItem from "../components/pack/PackCartItem.vue";

export default {
  name: "Cart",
  props: ["activeCategory"],
  components: {
    PackCartItem,
  },
  data: function () {
    return {
      imgDomain: "",
    };
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
  },
  computed: {
    hasItems() {
      return this.$parent.cartContents.length > 0;
    },
  },
  methods: {
    incrementPack(item, id) {
      this.$parent.addToCart(item, id, 1, "pack");
    },
    decrementPack(item, id) {
      this.$parent.removeOneFromCart(item, id, "pack");
    },
    removePack(item, id) {
      this.$parent.removeFromCart(item, id, "pack");
    },
    incrementItem(item, id) {
      this.$parent.addToCart(item, id);
    },
    decrementItem(item, id) {
      this.$parent.removeOneFromCart(item, id);
    },
    removeItem(item, id) {
      this.$parent.removeFromCart(item, id);
    },
    toOrder() {
      this.$router.push({ path: "/order" });
    },
  },
};
</script>

<style scoped>
.item + .pack-item,
.pack-item + .item,
.pack-item + .pack-item {
  margin-top: 16px;
}


</style>
