import { ref } from 'vue';
import axios from '@/axios';

export default function usePacks() {
  const packs = ref([]);
  const loading = ref(false);
  const error = ref(null);

  const fetchPacks = async () => {
    loading.value = true;
    error.value = null;

    try {
      const response = await axios.get('/packs');
      if (response.data.status === 'OK' && response.data.payload) {
        packs.value = response.data.payload;
        return response.data.payload;
      } else {
        throw new Error('Failed to fetch packs data');
      }
    } catch (err) {
      error.value = err.message || 'An error occurred while fetching packs';
      console.error('Error fetching packs:', err);
      throw err;
    } finally {
      loading.value = false;
    }
  };

  // Get a single pack by ID
  const getPack = (id) => {
    return packs.value.find(pack => pack.id === id) || null;
  };

  return {
    packs,
    loading,
    error,
    fetchPacks,
    getPack
  };
} 