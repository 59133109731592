<template>
  <div>
    <div class="item">
      <div class="delete" @click="removeFromCart">
        <img src="./../../assets/bin.svg" class="img" />
      </div>

      <div class="preview">
        <img
          v-if="cartItem.item && cartItem.item.img_url"
          :src="cartItem.item.img_url"
          alt="Pack icon"
          class="img"
        />
      </div>

      <div class="title name">
        <div class="pack-type">PACK</div>
        <div>{{ cartItem.item.title }}</div>
      </div>

      <div class="count">
        <div class="count__item minus" @click="decrementQuantity"></div>
        <input type="number" :value="cartItem.count" step="1" readonly />
        <div class="count__item plus" @click="incrementQuantity"></div>
      </div>

      <div class="price">
        {{ formatPrice(cartItem.item.price) }}
      </div>
    </div>

    <div class="pack-details" v-if="hasItems">
      <div class="pack-details-content">
        <div class="details-label">Details:</div>
        <div class="details-content">
          <div class="details-columns">
            <div
              v-for="(column, colIndex) in itemColumns"
              :key="`col-${colIndex}`"
              class="details-column"
            >
              <div
                v-for="(item, itemIndex) in column"
                :key="`item-${colIndex}-${itemIndex}`"
                class="details-item"
              >
                {{ item.title }} <span v-if="item.quantity > 1">({{ item.quantity }}x)</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PackCartItem",
  props: {
    cartItem: {
      type: Object,
      required: true,
    },
  },
  inject: ['getCurrency'],
  computed: {
    hasItems() {
      return this.cartItem.item && 
             this.cartItem.item.items && 
             this.cartItem.item.items.length > 0;
    },
    itemColumns() {
      if (!this.hasItems) return [[]];

      // Split items into two columns
      const items = this.cartItem.item.items;
      const midpoint = Math.ceil(items.length / 2);

      // Return an array of two arrays (columns)
      return [items.slice(0, midpoint), items.slice(midpoint)];
    }
  },
  methods: {
    incrementQuantity() {
      this.$emit(
        "increment",
        this.cartItem.item,
        this.cartItem.item_id,
        "pack"
      );
    },
    decrementQuantity() {
      this.$emit("decrement", this.cartItem, this.cartItem.item_id, "pack");
    },
    removeFromCart() {
      this.$emit("remove", this.cartItem.item, this.cartItem.item_id, "pack");
    },
    formatPrice(price) {
      // Use the injected currency symbol
      return `${Number(price).toFixed(2)} ${this.getCurrency()}`;
    },
  },
};
</script>

<style scoped>
.pack-type {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #4a4859;
  margin-bottom: 6px;
}

.pack-details {
  background-color: #eae8f0;
  width: 100%;
  margin-top: 0;
  display: block;
  clear: both;
}

.pack-details-content {
  max-width: 1440px;
  margin: 0 auto;
  padding: 24px 32px;
  width: 100%;
}

.details-label {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #0e0d13;
  margin-bottom: 24px;
  text-align: left;
}

.details-content {
  width: 100%;
}

.details-columns {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
}

.details-column {
  flex: 0 0 33%;
  max-width: 33%;
  box-sizing: border-box;
  padding: 0 10px;
}

.details-item {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: #0e0d13;
  text-decoration: underline;
}

@media (max-width: 767px) {
  .details-columns {
    justify-content: flex-start;
  }

  .details-column {
    flex: 1 1 100%;
    max-width: 100%;
    width: 100%;
    padding: 0;
  }

  .details-column + .details-column {
    margin-top: 16px;
  }

  .pack-details-content {
    padding: 16px;
  }
}
</style>
