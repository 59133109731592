<template>
  <div class="pack-container">
    <transition name="fade">
      <div class="item-added-overlay" v-if="addedItemId() == pack.id">
        <span class="added-text">Added</span>
      </div>
    </transition>
    <div class="pack-image-block">
      <img
        v-if="pack.image_url"
        :src="pack.image_url"
        class="pack-image"
        alt="Pack image"
      />
      <div v-else class="pack-image-placeholder">{{ getPackInitial() }}</div>
    </div>

    <div class="pack-content-block">
      <div class="pack-header">
        <h3 class="pack-title">{{ pack.title }}</h3>
        <div class="pack-price">
          <span v-if="pack.old_price" class="old-price">{{
            formatPrice(pack.old_price)
          }}</span>
          {{ formatPrice(pack.price) }}
        </div>
      </div>

      <div class="pack-actions">
        <div class="quantity-container">
          <div class="count">
            <div class="count__item minus" @click="decrementQuantity"></div>
            <input
              type="number"
              v-model="quantity"
              readonly
              class="quantity-input"
            />
            <div class="count__item plus" @click="incrementQuantity"></div>
          </div>
        </div>
        <button @click="addToCart" class="add-to-cart-btn">ADD TO CART</button>
      </div>

      <div class="pack-description">
        <p class="description-title">Package description:</p>
        <p class="description-text">{{ pack.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PackStoreItem",
  props: {
    pack: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      default: ''
    }
  },
  inject: ['addedItemId', 'getCurrency'],
  data() {
    return {
      quantity: 1,
    };
  },
  methods: {
    incrementQuantity() {
      this.quantity++;
    },
    decrementQuantity() {
      if (this.quantity > 1) {
        this.quantity--;
      }
    },
    addToCart() {
      this.$emit("addToCart", this.pack, this.pack.id, this.quantity, 'pack');
    },
    formatPrice(price) {
      // Use provided currency prop first, then fall back to inject
      const symbol = this.currency || this.getCurrency();

      if (price % 1 === 0) {
        return `${Number(price).toFixed(0)} ${symbol}`;
      } else {
        return `${Number(price).toFixed(2)} ${symbol}`;
      }
    },
    getPackInitial() {
      return this.pack.title ? this.pack.title.charAt(0).toUpperCase() : "P";
    },
  },
};
</script>

<style scoped>
/* Root styles - defaults for smaller screens */
.pack-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  position: relative;
}

.pack-image-block {
  background: #ffffff;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 280px;
  width: 100%;
}

.pack-letter {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.tooltip-icon {
  position: absolute;
  top: 24px;
  right: 24px;
  width: 38px;
  height: 38px;
}

.pack-content-block {
  background: #ffffff;
  padding: 24px;
  margin-top: 16px;
  width: 100%;
}

.pack-header {
  display: flex;
  flex-direction: column;
}

.pack-title {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  color: #0e0d13;
  text-align: left;
}

.pack-price {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #5bc530;
  margin-top: 16px;
}

.pack-actions {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  width: 100%;
}

.quantity-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.count {
  overflow: hidden;
  display: inline-flex;
  vertical-align: middle;
  height: 36px;
  width: 125px;
}

.count__item {
  height: 36px;
  width: 36px;
  background: #e61444;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.count__item::before {
  content: "";
  width: 18px;
  height: 4px;
  background: #fff;
}

.count__item.plus::after {
  content: "";
  width: 4px;
  height: 18px;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.quantity-input {
  width: 53px;
  padding: 0;
  border: 0;
  line-height: 36px;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #0e0d13;
  text-align: center;
  border-radius: 0;
  background: #e0dfe6;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Hide number input arrows */
.quantity-input::-webkit-inner-spin-button,
.quantity-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.add-to-cart-btn {
  width: 100%;
  height: 60px;
  margin-top: 16px;
  background-color: transparent;
  background-image: url("../../assets/btn-bg-red.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  color: #e61444;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  padding: 19px 32px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
}

.pack-description {
  margin-top: 26px;
  text-align: left;
}

.description-title {
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #0e0d13;
}

.description-text {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #15141b;
  margin-top: 10px;
}

/*  Desktop Styles */
@media screen and (min-width: 1025px) {
  .pack-container {
    flex-direction: row;
    gap: 16px;
  }

  .pack-image-block {
    width: 321px;
    height: 321px;
    min-width: 321px;
  }

  .pack-letter {
    width: 120px;
    height: 120px;
  }

  .pack-content-block {
    flex: 1;
    padding: 42px 40px;
    margin-top: 0;
  }

  .pack-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .pack-title {
    font-size: 32px;
    line-height: 44px;
    max-width: 70%;
  }

  .pack-price {
    margin-top: 0;
    text-align: right;
  }

  .quantity-container {
    justify-content: flex-start;
  }

  .pack-actions {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }

  .add-to-cart-btn {
    text-wrap: nowrap;
    width: fit-content;
    margin-top: 0;
  }

  .count {
    height: 48px;
    width: 169px;
  }

  .count__item {
    height: 48px;
    width: 48px;
  }

  .quantity-input {
    width: 75px;
    line-height: 48px;
  }
}
</style>
